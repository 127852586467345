<template>
    <div class="index">
        <!-- <div class="intro">
            选择城市前，会自动定位当前城市<br/>
            选择城市后，会取消对当前城市的自动定位
        </div> -->
        <div v-if="visual_city == true">
            <div class="address-bar">
                <div class="address-choose">
                    <span class="address-choose-title">当前城市</span>
                    <router-link tag="span" :to="{name: 'kfc-cityList'}" class="address-choose-content">{{cityName}}<span
                        class="address-choose-icon">丨</span></router-link>
                </div>
                <van-row type=:flex justify="left">
                        <van-cell-group>
                            <van-field 
                            v-model="keyWord" 
                            size="large"
                            placeholder="请输入餐厅关键字"
                            icon="question"
                            >
                            <van-button slot="button" icon="search" type="primary" @click="onSearch()" >查询</van-button>
                            </van-field>
                        </van-cell-group>
                </van-row>

                <div class="fast-sel-bar">
                    <!-- <van-cell-group  v-for="(store) in storeList" :key="store.id" > -->
                        <van-cell v-for="store in storeList" :key="store.id" :id="store.id" 
                        :title="'餐厅名称:'+ store.store_name" size="large" 
                        :label="'地址:'+ store.store_addr | ellipsis" 
                        :data-id="store.id"
                        :data-name="store.store_name"
                        :data-addr="store.store_addr"
                        @click="choosePlace">
                        <!-- <van-icon slot="right-icon" name="search" class="custom-icon" /> -->
                        </van-cell>
                    <!-- </van-cell-group> -->
                </div>

            </div>
        </div>

        <div v-if="visual_sold == true">
            <van-row type="flex" class="each-row">
                <van-col span="6"></van-col>
                <van-col span="16">
                 <van-tag type="primary" class="top-tag">订单信息：</van-tag>
                </van-col>
                <van-col span="6"></van-col>
            </van-row>

            <van-divider dashed />

            <van-row type="flex" class="each-row">
                <van-col span="1"></van-col>
                <van-col span="22">
                <van-tag type="primary" class="phone-tag">城市： <span> {{ city_name }} </span></van-tag>       
                </van-col>
                <van-col span="6"></van-col>
            </van-row>
            <van-row type="flex" class="each-row">
                <van-col span="1"></van-col>
                <van-col span="22">
                <van-tag type="primary" class="phone-tag">餐厅：<span> {{ store_name }} </span> </van-tag>    
                </van-col>
                <van-col span="6"></van-col>
            </van-row>
            <van-row type="flex" class="each-row">
                <van-col span="1"></van-col>
                <van-col span="22">
                <van-tag type="primary" class="phone-tag">取餐手机：<span> {{ phone }} </span> </van-tag>    
                </van-col>
                <van-col span="6"></van-col>
            </van-row>
            <van-row type="flex" class="each-row">
                <van-col span="1"></van-col>
                <van-col span="22">
                <van-tag type="primary" class="phone-tag">取餐备注：<span> {{ remark }} </span> </van-tag>    
                </van-col>
                <van-col span="6"></van-col>
            </van-row>


            <van-divider dashed />

            <van-row type="flex" class="each-row">
                <van-col span="1"></van-col>
                <van-col span="22">
                <van-tag type="primary" class="sub-tag">在本页停留等待获取取餐码</van-tag> 
                </van-col>
                <van-col span="1"></van-col>
            </van-row>


            <van-divider dashed />

            <van-row type="flex" class="each-row">
                <van-col span="1"></van-col>
                <van-col span="22">
                <van-tag type="primary" class="red-tag">如果长时间没有获取餐码，请重复操作一次。如在APP上发送两次还没收到验证码，请联系客服</van-tag> 
                </van-col>
                <van-col span="1"></van-col>
            </van-row>

            <van-divider dashed />


            <van-row type="flex" class="each-row">
                <van-col span="1"></van-col>
                <van-col span="13">
                <van-tag type="primary" class="verify-tag"> 取餐码： <span> {{ fetch_code }} </span> </van-tag> 
                </van-col>
                <van-col span="3">
                <van-button plain type="primary"
                    size="small"
                    @click="reflash()"
                    >刷新</van-button>
                </van-col>
            </van-row>

        </div>

        <van-dialog
            v-model="visual_store_content"
            show-cancel-button
            :beforeClose="beforeStoreContentClose"
            >
            <div style="width: 100%; height: 180px; display: flex; flex-direction: column; align-items: center;">
            <span style="width: 100%; line-height: 26px; font-size: 16px; color: #ffffff; text-align: center; background-color: #37AAEA;">填写手机和备注</span>

            <van-divider dashed />
                <van-tag type="primary" class="sub-tag">{{ store_content }}</van-tag> 
            <van-field
                v-model="phone"
                label="取餐手机:"
                placeholder="请输入取餐手机(必填)"
            />
            <van-field
                v-model="remark"
                label="取餐备注:"
                placeholder="请输入取餐备注(选填)"
            />
            <van-divider dashed />
            </div>
        </van-dialog>

        <van-dialog
            v-model="visual_phone_remark"
            show-cancel-button
            :beforeClose="beforePhoneRemarkClose"
            @confirm="handlePhoneRemarkConfirm"
            >
            <div style="width: 100%; height: 160px; display: flex; flex-direction: column; align-items: center;">
                <span style="width: 100%; line-height: 26px; font-size: 16px; color: #ffffff; text-align: center; background-color: #37AAEA;">确认下单</span>

                <van-divider dashed />
                    
                <div style="display: flex; flex-direction: column; align-items: left;">
                    <van-tag type="primary" style="margin-left:0.2rem" class="sub-tag">{{ store_content }}</van-tag>
                    <van-tag type="primary" style="margin-left:0.2rem" class="sub-tag">{{ phone_content }}</van-tag>
                    <van-tag type="primary" style="margin-left:0.2rem" class="sub-tag">{{ remark_content }}</van-tag>
                </div>
                    
                <van-divider dashed />
                <div style="display: flex; flex-direction: column; align-items: left;">
                    <van-tag type="primary" style="margin-left:0.2rem" class="sub-tag">{{ phone_remark }}</van-tag>
                </div>
            </div>
        </van-dialog>

    </div>
</template>

<script>
import axios from 'axios';
// import Clipboard from "clipboard";
import {Field, Button} from 'vant'
import { CellGroup, Cell, Row , Tag, Divider,Toast } from 'vant';

export default {
  inject:['reload'],
    components: {
        vanField:Field,
        vanButton:Button, 
        vanCell:Cell,
        vanRow:Row,
        vanTag:Tag,
        vanDivider:Divider,
        vanCellGroup:CellGroup,
    },
    props: ['cityName', 'shouldChangeCity'],
    data() {
        return {
            keyWord:'',
            storeList:[],
            token:'',
            fetch_code:'等待中',
            visual_city:false,
            visual_sold:false,
            visual_code:false,
            visual_store_content:false,
            visual_phone_remark:false,
            city_name:'',
            store_name:'',
            store_content:'',
            phone_content:'',
            remark_content:'',
            phone_remark:'',
            phone:'',
            remark:'',
        };
    },  
    filters: {
        ellipsis (value) {
        if (!value) return ''
        if (value.length > 18) {
            return value.slice(0,18) + '...'
        }
        return value
        }
    },
    created() {
    },
    mounted() {
        this.$nextTick(() => {
            // this.baiduLocation();
        });
    },
    methods: {
        reflash(){
            this.reload()
        },
        choosePlace(e){
            this.city_name = this.cityName;
            this.store_name =  e.currentTarget.dataset.name;
            //window.console.log(this.cityName);
            // window.console.log(e.currentTarget.dataset.name);
            //window.console.log('城市：' + this.city_name + ' 餐厅：' + this.store_name + ' && ');
            this.store_content = '城市：' + this.city_name + ' 餐厅：' + this.store_name;
            this.visual_store_content = true;
        },
        beforeStoreContentClose(action, done) {
            if(action === 'confirm') {
                if(!this.phone) {
                    this.$toast("请输入取餐手机")
                    return done(false) //不关闭弹框
                }else{
                    this.phone_content = '取餐手机：' + this.phone;
                    this.remark_content = '取餐备注：' + this.remark;
                    this.phone_remark = '订单确认后无法修改，确认么？';
                    setTimeout(done, 1000)
                    this.visual_phone_remark = true;
                }
                // setTimeout(done, 1000)
                // window.console.log('confrm')
            } else if(action === 'cancel') {
            //    window.console.log('cancel')
                return done() //关闭
            }
        },
        beforePhoneRemarkClose(action, done) {
            if(action === 'confirm') {
                 setTimeout(done, 1000)
                 window.console.log('confrm')
            } else if(action === 'cancel') {
                window.console.log('cancel')
                return done() //关闭
            }
        },
        handlePhoneRemarkConfirm(){
            const shop = this.$route.params.shop;
            const token = this.$route.params.token;            
            window.console.log(   '|' + this.phone + '|'+ this.remark + '|');            
                
            let url = '/kfcsold/kfc-hb-1m/' + shop + '/' + token + '/' + this.city_name + '/' + this.store_name 
                        + '/' + this.phone + '/none' ; 
                if(this.remark!= ''){                    
                    url = '/kfcsold/kfc-hb-1m/' + shop + '/' + token + '/' + this.city_name + '/' + this.store_name 
                        + '/' + this.phone + '/' + this.remark ; 
                }

            axios.get(url).then(
                res => {
                let result = res.data.data
                let code = result.code;
                if(code == '0'){
                    window.console.log(result);
                    let visual_city = result.visual_city;
                    let visual_sold = result.visual_sold;
                    let visual_code = result.visual_code;                   
                    let fetch_code = result.fetch_code
                    if(visual_city == '1'){
                        this.visual_city = true
                    }else{
                        if(visual_sold == '1'){
                            this.visual_sold = true                       
                            if(visual_code == '1' ){
                                this.fetch_code = fetch_code;
                                this.visual_code = true;
                            }
                        }
                    }              
                    // reflash
                        this.reload()
                    
                    }else{
                        window.console.log('failed');
                    }
                }
            ).catch(
                rsp => {
                    window.console.log(rsp);          
                },
            );
        },
        onSearch(){
            if (this.cityName == '请选择'){
                Toast('请选择城市')  
            }else{
                let url = '/kfcstore/' + this.cityName + '/all'; 
                if(this.keyWord){                    
                    url = '/kfcstore/' + this.cityName + '/' + this.keyWord;  
                }
                //window.console.log(url,'url')  
                axios.get(url).then(
                    res => {
                        let result = res.data.data
                        let code = result.code;
                        let data = result.data;
                        if(code == '0'){
                            this.storeList = data;
                            // window.console.log(data,'data');
                        }else{
                            Toast('没有查询到门店，请换一个关键字')  
                        }
                    }
                ).catch(
                    rsp => {
                        window.console.log(rsp);          
                    },
                );
            }
        },

        /**
         * [百度地图定位]
         * @param  {[type]}       [无入参]
         * @return {[type]}       [无返回值]
         */
        baiduLocation() {
            this.$parent.shouldChangeCity = true;
            let _that = this;
            /*eslint-disable*/
            // 百度地图定位
            var map = new BMap.Map("allmap");
            var point = new BMap.Point(116.331398, 39.897445);
            map.centerAndZoom(point, 12);
            var geoc = new BMap.Geocoder();
            var geolocation = new BMap.Geolocation();
            geolocation.getCurrentPosition(function (r) {
                if (this.getStatus() === BMAP_STATUS_SUCCESS) {
                    console.log('定位成功');
                    console.log(r.point);
                    geoc.getLocation(r.point, function (rs) {
                        var addComp = rs.addressComponents;
                        console.log(addComp.province + ", " + addComp.city + ", " + addComp.district + ", " + addComp.street + ", " + addComp.streetNumber);
                        if (_that.shouldChangeCity) {
                            _that.$parent.cityName = addComp.city.replace('市', '');
                        }
                    });
                }
                else {
                    console.log('failed' + this.getStatus());
                }
            }, {enableHighAccuracy: true});
            /*eslint-disable*/
        }
    },
    sockets:{
    // 通信的name
    //这里是监听connect事件
    connect: function(){     
      //window.console.log('建立连接 & 加入房间.' + this.token)      
      this.$socket.emit('join',{'token':this.token})
    },

    disconnect: function(){
      //window.console.log('断开连接')
    },

    reconnect: function(){
      //window.console.log('重新连接')
      this.$socket.emit('conect')

    },

    error:function(){
      this.$socket.destroy()
    },

    close:function(){
      this.$socket.destroy()
    },

    chat_message: function(data){      
      //window.console.log('接收数据',data)
      let socket_verify = data['verify']
      if(typeof(socket_verify)=="undefined" || socket_verify == '' || socket_verify == null){        
        //window.console.log('none socket_verify')
      }else{
        //window.console.log('get socket_verify')
        this.verify = socket_verify
        this.visual_verify = true
      }
    }
  },
  created(){

    (function () {
      var viewportTag = null;
      var metaTags = document.getElementsByTagName('meta');
      for (var i = 0; i < metaTags.length; i++) {
        if (metaTags[i].getAttribute('name') === "viewport") {
          viewportTag = metaTags[i];
          break;
        }
      }
      if (!viewportTag) {
        viewportTag = document.createElement("meta");
        viewportTag.setAttribute('name', 'viewport');
      }

      var viewportTagContent = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=0';

      // Detect if iOS device
      if (/(iPhone|iPod|iPad)/i.test(window.navigator.userAgent)) {
        // Get iOS major version
        var iosVersion = parseInt((window.navigator.userAgent).match(/OS (\d+)_(\d+)_?(\d+)? like Mac OS X/i)[1]);
        // Detect if device is running >iOS 11
        // iOS 11's UIWebView and WKWebView changes the viewport behaviour to render viewport without the status bar. Need to override with "viewport-fit: cover" to include the status bar.
        if (iosVersion >= 11) {
          viewportTagContent += ', viewport-fit=cover';
        }
      }

      // Update viewport tag attribute
      viewportTag.setAttribute('content', viewportTagContent);
    })()

    const shop = this.$route.params.shop;
    const token = this.$route.params.token;
    if(shop && token){
      this.token = token
      // 发ajax请求，用以获取数据，此处地址意思是查询 github中 vue 星数最高的项目
      const url = '/kfccode/kfc-hb-1m/' + shop + '/' + token;   

      axios.get(url).then(
          res => {
            let result = res.data.data
            let code = result.code;
            if(code == '0'){
              window.console.log(result);
              let visual_city = result.visual_city;
              let visual_sold = result.visual_sold;
              let visual_code = result.visual_code;
              let city_name = result.city_name;
              let store_name = result.store_name;
              let fetch_code = result.fetch_code;
              let phone = result.phone;
              let remark = result.remark;
              if(visual_city == '1'){
                this.visual_city = true
              }else{
                if(visual_sold == '1'){
                    this.visual_sold = true
                    this.city_name = city_name
                    this.store_name = store_name
                    this.phone = phone;
                    this.remark = remark;
                    if(visual_code == '1' ){
                        this.fetch_code = fetch_code;
                        this.visual_code = true;
                    }
                }
              }              
              // 连接 websocket，进入房间
              if(typeof(fetch_code)=="undefined" || fetch_code == '' || fetch_code == null){
                    window.console.log('none fetch_code');
                }else{
                    window.console.log('got fetch_code');
                    this.fetch_code = fetch_code
                    this.visual_code = true
                }
                // 可能重新获得，so 还是要监听
                this.$socket.connect()
              
            }else{
              window.console.log('failed');
            }
          }
      ).catch(
          rsp => {
            window.console.log(rsp);          
          },
      );
    }else{
      this.visual_phone = false
      window.console.log('noe allow');
    }
    
  },
  beforeDestroy() {
    this.$socket.emit('close',{'token':this.token})
    this.$socket.close()
  }
};
</script>


<style lang="scss" scoped>
    .index {
        width: 100%;
        overflow-x: hidden;
        .intro {
            background-color: white;
            padding: 20px;
            font-size: 18px;
        }
        .address-bar {
            padding: 0 20px;
            background-color: #FFFFFF;
            border-top: #eeeeee solid 2px;
            .address-choose {
                height: 68px;
                line-height: 68px;
                display: flex;
                font-size: 24px;
                .address-choose-title {
                    flex: 1;
                    font-size: 24px;
                    color: #111111;
                    font-weight: 500;
                    text-align: left;
                }
                .address-choose-content {
                    flex: 1;
                    color: #999999;
                    text-align: right;
                    .address-choose-icon {
                        font-family: 'iconfont';
                        padding-left: 10px;
                    }
                }
            }
        }
        border-top: #eeeeee solid 2px;
        .search-input {
            height: 68px;
            line-height: 68px;
            display: flex;
            font-size: 24px;
            .search-input-title {
                flex: 1;
                font-size: 24px;
                color: #111111;
                font-weight: 500;
                text-align: left;
            }
            .search-input-content {
                flex: 1;
                color: #999999;
                text-align: right;
                .search-input-icon {
                    font-family: 'iconfont';
                    padding-left: 10px;
                }
            }
        }
    }
</style>

<style scoped>
.body {
  font-size: 0.3rem;
}
.each-row{
  font-size: 1px;;
}
.top-tag {
  font-size: 0.6rem;
  line-height: 0.8rem;
}

.sub-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:black;
  background-color:yellow;
}

.red-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:crimson;
  background-color:#DFE0E7;
}

.blue-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:blue;
  background-color:white;
  margin-bottom: 10px;
}

.phone-tag {
  font-size: 0.55rem;
  line-height: 0.8rem;
  color:black;
  background-color:white;
  margin-top: 2px;
  margin-bottom: 3px;
}

.verify-tag {
  font-size: 0.55rem;
  line-height: 0.8rem;
  color:black;
  background-color:white;
  margin-top: 2px;
  margin-bottom: 3px;
}
.note-row{
  font-size: 0.35rem;
  color:gray;
  margin-bottom: 5px;
}
.dialog-button-bg {
  background: white;
  text-align: center;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>